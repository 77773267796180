.detailed-blog{
    margin: 2.0833333333333335vw 0 0;
    margin-right: 0.9375vw;
    display: flex;
    flex-direction: row;
    padding: 2.6041666666666665vw 3.5416666666666665vw 2.1875vw 2.7604166666666665vw;
    transition: 0.5s ease-in-out;
    border-radius: 5px;
    box-shadow: 25px 25px 75px 0 rgba(204, 204, 204, 0.15);
    background-color: #fff;
} 

.detailed-blog-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 75vh;
}

.detailed-blog-content{
    width: 100%;
}

.detailed-blog-breadcrum-container{
    display: flex;
    align-items: flex-start;

}

.detailed-blog-breadcrum-container img{
    position: relative;
    object-fit: contain;
    top: 0.2604166666666667vw;
    margin-left: 0.78125vw;
    margin-right: 0.78125vw;
}

.detailed-blog-breadcrum-links{
    font-family: OpenSans;
    font-size: 0.9375vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: rgba(44, 51, 62, 0.5);
}

.detailed-blog-active-breadcrum{
    font-family: OpenSans;
    font-size: 0.9375vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #2c333e;
}


.detailed-blog-content h2{
    font-family: OpenSans;
    font-size: 1.7708333333333333vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    margin: 0;
}

.detailed-blog-detailed-content img{
    max-width: 41.666666666666664vw;
    object-fit: contain;
}

.detailed-blog-detailed-content{
    overflow-wrap: break-word;
}

@media (max-width:887px){
    .detailed-blog-container{
        align-items: center;
     }
     .detailed-blog{
         width: 85%;
     }
}