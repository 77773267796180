.detailed-case{
    margin: 2.0833333333333335vw 0 0;
    margin-right: 0.9375vw;
    display: flex;
    flex-direction: row;
    padding: 2.6041666666666665vw 3.5416666666666665vw 2.1875vw 2.7604166666666665vw;
    transition: 0.5s ease-in-out;
    border-radius: 5px;
    box-shadow: 25px 25px 75px 0 rgba(204, 204, 204, 0.15);
    background-color: #fff;
} 

.detailed-case-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 75vh;
}

.detailed-case-content{
    width: 100%;
}

.detailed-case-breadcrum-container{
    display: flex;
    align-items: flex-start;

}

.detailed-case-breadcrum-container img{
    position: relative;
    object-fit: contain;
    top: 0.2604166666666667vw;
    margin-left: 0.78125vw;
    margin-right: 0.78125vw;
}

.detailed-case-breadcrum-links{
    font-family: OpenSans;
    font-size: 0.9375vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: rgba(44, 51, 62, 0.5);
}

.detailed-case-active-breadcrum{
    font-family: OpenSans;
    font-size: 0.9375vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #2c333e;
}


.detailed-case-content h2{
    font-family: OpenSans;
    font-size: 1.7708333333333333vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    margin: 0;
}

.detailed-case-detailed-content img{
    max-width: 41.666666666666664vw;
    object-fit: contain;
}

.detailed-case-detailed-content{
    overflow-wrap: break-word;
}

.detailed-search-icon{
    width: 1.8229166666666667vw;
    position: relative;
    bottom: 3.125vw;
    right: 3.3854166666666665vw;
}

.detailed-search-case{
  width: 62.5vw;
  border-radius: 30px;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.15);
  background-color: #fff;
  position: relative !important;
  bottom: 1.6666666666666667vw;
  right: -8px;
}

.detailed-search-case .MuiInputBase-root input{
    padding: 2.03125vw 1.5625vw 1.6666666666666667vw !important;
    font-size: 1.3020833333333333vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-align: left;
    color: #a0a0a0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.detailed-case-card-date{
    font-family: OpenSans !important;
    font-size: 0.8333333333333334vw;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63 !important;
    letter-spacing: normal !important;
    text-align: left;
    color: #999 !important;
    top: 5px;
    position: relative;
}

@media (max-width:887px){
    .detailed-case-container{
        align-items: center;
     }
     .detailed-case{
         /* width: 85%; */
     }
}