.securityContainer {
    padding-top: 140px;
}

.securityTitle {
    font-size: 54px;
    font-weight: bold;
    margin-bottom: 24px;
}

.securitySubTitle {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 48px;
}

.security-main-image{
    /* width:  893px; */
    width: 234px;
    margin-left: 25%;
}

.securityLeftContent{
    padding-left: 8.86%;
}

.securityPadding{
    padding-left: 8.86%;
    padding-right: 8.86%;
}

.securityMainContent{
    background-color: #5cb8b226;
    padding: 30px 0;
}

.securityMainContent2{
    padding: 30px 0;
}

.securityMainContent2 ul{
    line-height: 1.875rem;
}


.securityTitle2 {
    font-size: 2.75rem;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
}

.securitySubTitle2 {
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 48px;
    text-align: center;
    line-height:1.875rem
}

.securityTitle3 {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
}

.securityTitle4 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.securitySubTitle3 {
    font-size: 17px;
    font-weight: normal;
    margin-bottom: 48px;
    line-height:1.875rem
}



.security-image{
    max-width: 53.125rem;
    width: 400px;
}

.security-image-right{
    float: right;
    padding-right: 100px;
}

.security-image-left{
    float: left;
    padding-left: 100px;
}

.security-image2{
    width: 100%;
}

.security-card{
    border-radius: 10px;
    padding: 20px 30px;
    background: rgba(243, 243, 243, 1);
    max-width: 480px;
}

.security-card-2{
    border-radius: 10px;
    padding: 20px 30px;
    /* background: rgba(243, 243, 243, 1); */
    background-color: white;
    box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,0.15);
    position: relative;
    max-width: 520px;
    cursor: pointer;
}

.security-card-2:hover{
    background: rgba(243, 243, 243, 1);
    transition: .5s ease-in-out;
}

.security-icon{
    font-size: 24px;
    position: relative;
    top: 5px;
    color: #5cb8b2;
}

.security-icon-container svg{
    fill: #5cb8b2 !important;
}

.security-icon-container{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    position: relative;
    left: 30px;
}

.security-card-overlay{
    background-color: #5cb8b226;
    position: absolute;
    right: 0;
    width: 30%;
    height: 100%;
    top: 0;
}

@media(max-width:1280px) {
    .security-image{
        max-width: 300px;
        width: 100%;
        position: relative;
    }
    .security-image-right{
        padding-right: 0;
    }
}

.right-0{
    position: relative;
    right: 100px !important;
}

.font-18{
    font-size: 18px;
}


