.w-100{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.collection-content{
    width: 100%;
    padding: 25px 60px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}
.add-post-content{
    width: 100%;
    padding: 25px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bhs-close{
    box-shadow: none !important;
}

.form-create-post{
    max-width: 900px;
    padding: 30px 45px;
    margin: 0 auto;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.collection-card-container{
    display: flex;
    width: 66%;
    flex-wrap: wrap;    
}

.no-post{
    padding-top: 30px;
    width:100%;
    text-align:center;
}

.card-avatar-collection{
    background-color: red;
}

.collection-card{
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12) !important;
    margin: 30px 1%;
    max-width: 275px;
    width: 100%;
}

.help-side{
    max-width: 425px;
    width: 24%;
    padding-top: 15px;
}

.help-side h2{
    padding-left: 16px;
    margin-top:0;
}

.card-media-addmore{
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    max-width: 840px;
    width: 100%;
    margin: 16px;
    display: flex;
    flex-direction: row;
    padding: 30px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.add-more{
    display: flex;
    justify-content: center;
}

.card-media {
    background-size: contain !important;
    height: 0;
    padding-top: 50.25%;
}

.card-content-article{
    width: 60%;
}

.card-footer-article{ 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-content-article h4{
    margin-top: 1px;
    margin-bottom: 1px;
}

.card-content-article p{
    margin-top: 8px;
}

.view-more{
    margin-left: auto !important;
    margin-right: 15px !important;
}

.breadcrumbs-container{
    width: 62%;
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 15px;
}

.breadcumb-link{
    cursor: pointer;
    color: inherit;
}

.breadcumb-link:hover{
    text-decoration: underline;
}

.icon-container-article{
    z-index: 1;
    display: inline-block;
    width: 15%;
    border-left:  1px solid lightgray;
}

.help-content-collection{
    display: flex;
    padding: 20px;
    min-height: 100vh;
}

.help-container{
    display: flex;
}

@media (max-width:1366px){
    .help-side-article{
        width: 18% !important;
    }

    .help-side{
        width: 18%;
    }

    .collection-card-container{
        width: 72%;
    }
}

@media (max-width:885px){
    .help-container{
        flex-direction: column;
    }
    .article-container{
        flex-direction: column;
    }
    .help-side{
        padding-top:20px;
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .help-side-article{
        padding-top:20px;
        width:100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .help-side-article h2{
        padding-left: 0 !important;
    }

    .collection-card-container{
        width: 100%;
    }
}

@media (max-width:561px){
    .breadcrumbs-container{
        width: 85%;
        max-width: 100%;
    }
}