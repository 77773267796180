.schedule-container{
    background-color: #fcfdfd;
    padding-top:  9.6875vw
}

.demo-text{
    padding-left: 8.854166666666666vw;
    font-size:  1.4583333333333333vw !important;
    margin-bottom: 3.6458333333333335vw;
}

.demo-text h3{
    margin-bottom: 0;
}
.register-company-demo{
  font-family: OpenSans;
  font-size: 1.6666666666666667vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.64px;
  color: #5cb8b2;
  padding-top: 2.0833333333333335vw;
  margin-bottom: 1.8229166666666667vw;
  margin-top: 0;
}

.register-company-demo TextField{
    font-family: OpenSans;
    margin: 0;
}

.demo-work{
    font-size:  0.9375vw;
}

.demo-image{
    width: 31.25vw;
}

.form-demo{
    width: 35vw;
    margin-bottom: 3.6458333333333335vw;
    padding: 0px 2.5vw 0px 2.6041666666666665vw;
    background: white;
    border-radius:8px;
    box-shadow: 15px 15px 75px 0 rgba(204, 204, 204, 0.15);
}

.btn-try-demo{
    width: 34.635416666666664vw;
    height: 3.125vw;
    margin: 1.8229166666666667vw 0.20833333333333334vw  2.0833333333333335vw 0.15625vw;
    border-radius: 10px;
    /* background-image: linear-gradient(to left, #7de0db 114%, #5cb8b2 17%); */
    background: linear-gradient(90deg, rgba(92,184,178,1) 0%, rgba(125,224,219,1) 100%);
    font-family: OpenSans;
    font-size:  1.1458333333333333vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 0.44px;
    text-align: center;
    color: #fff;
    border:none;
    cursor: pointer;
}

.btn-try-demo:hover{
    background-color: rgb(50, 91, 178) !important;
}

.form-content .MuiFormControl-root label{
    font-family: OpenSans;
    font-size:  1.1416666666666667vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0;
    letter-spacing: normal;
    text-align: left;
    color: #222;
  }
.form-content .MuiFormControl-root .MuiInputBase-root{
    color: #5cb8b2 !important;
    
}
.form-content .MuiFormControl-root{
    margin-bottom: 1.8229166666666667vw;
}
.column-2 .MuiFormControl-root{
    width: 16.145833333333332vw;
}

.MuiInputLabel-asterisk{
    color: #e69875;
}

.MuiInputBase-input{
    font-family: OpenSans !important;
}

.MuiInput-underline:before{
    border-bottom: 1px solid #ccc !important;
}
.MuiInput-underline:after{
    border-bottom: 2px solid #5cb8b2 !important;
}


