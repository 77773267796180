.modules-container{
    width: 100%;
}

.modules-header{
    background: url('../../assets/img/module-head-bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 24.739583333333332vw;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modules-title{
    font-family: OpenSans;
    font-size: 4.6875vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: 1.8px;
    text-align: left;
    color: #fff;
}

.modules-content{
    padding: 7.8125vw 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: #fcfdfd;
}

.modules-content-title{
    opacity: 0.9;
    font-family: OpenSans;
    font-size: 2.6041666666666665vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 1px;
    text-align: center;
    color: #2c333e;
    margin: 0;
}

.modules-content-subtitle{
    margin:  0.78125vw 0 5.208333333333333vw;
    opacity: 0.89;
    font-family: OpenSans;
    font-size:  1.5625vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.3px;
    text-align: center;
    color: #5cb8b2;
}

.modules-box-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.5s ease-in-out;
    height: 8.59375vw;
    margin: 0 6.854167vw;
}

.override-box{
    margin-right: 0 !important;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.notActivated{
    position: relative;
    box-shadow:  inset 0 0 0 0 #fff;
    box-shadow: 10px 10px 30px 0 rgba(204, 204, 204, 0.2);
    transition: 0.5s ease-in-out;
}

.activated{
    box-shadow: inset 0 8.697916666666666vw 0 0px #7addd7;
    transition:  0.5s ease-in-out;
    height: 8.697916666666666vw;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.activated .demo-module-logo{
    position: relative;
    top: 0.78125vw;
    transition: top 0.5s ease-in-out;
}

.demo-module-logo{
    position: relative;
    top: 0;
    transition: top 0.5s ease-in-out;
}

.modules-overview-title{
    height: 3.6875vw;
    border-radius: 5px;
    background-color: #7addd8;
    font-family: OpenSans;
    font-size: 1.7708333333333333vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modules-overview-container{
    margin: 0 6.854167vw;
}

.modules-overview-content{
    padding: 2.604167vw 3.385417vw 2.604167vw 2.864583vw ;
    background-color: #fff;
    box-shadow: 25px 25px 75px 0 rgba(204, 204, 204, 0.15);
}

.modules-description{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
}

.modules-description-number{
    width: 0.6458333333333334vw;
    height: 1.25vw;
    margin: 2.34375vw 2.6041666666666665vw 2.34375vw 0;
    padding:  0.6770833333333334vw  0.9895833333333334vw 0.6770833333333334vw 1.0416666666666667vw;
    background-color: #7de0db;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: HelveticaNeue;
    font-size: 1.0416666666666667vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}

.modules-description-text{
    font-family: OpenSans;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #091212;
    width: 100%;
}

.modules-description-text h3{
    margin: 0;
}

.modules-thumbnails{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.modules-thumbnail{
    width: 23.958333333333332vw;
    /* height: 22.5vw; */
    margin: 0 0 1.0416666666666667vw;
    padding: 1.1458333333333333vw 0;
    border-radius: 0.5208333333333334vw;
    background-color: #e9f0fb;
}

.modules-thumbnail-img{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 0.5208333333333334vw;
    height: 18.229166666666668vw;
    object-fit: cover;
}

.modules-thumbnail-desc{
    display: flex;  
    justify-content: center;  
}

.modules-thumbnail-desc h5{
    padding: 0.20833333333333334vw 0.5208333333333334vw;
    height: 1.6666666666666667vw;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    background-color: #fff;
    font-family: OpenSans;
    font-size: 0.9375vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: #6cbfba;
    margin: 0;
    margin-top: 0.5208333333333334vw;
}

.modules-thumbnail-arrow{
    display: flex;
    width:  23.958333333333332vw;
    justify-content: center;
}

.modules-thumbnail-arrow .left{
    margin-right: 58.5px;
}

.modules-thumbnail-arrow img{
    width: 2.6041666666666665vw;
    cursor: pointer;
}

.modules-thumbnail-imgs{
    width: 23.958333333333332vw;
    margin-top: 0.5625vw;
    display: flex;
}

.modules-thumbnail-imgs img{
    width: 7.291666666666667vw;
    height: 7.291666666666667vw;
    margin-right: 1.0416666666666667vw;
    border-radius: 0.4166666666666667vw;
    cursor: pointer;
}

.modules-thumbnail-imgs .activeThumbnail{
    width:  6.875vw;
    height: 6.875vw;
    border: solid 0.20833333333333334vw #7de0db;
}

.modules-contact-us{
    display: flex;
    width: 100%;
    /* padding: 128px 422px 127px; */
    justify-content: center;
    align-items: center;
    box-shadow: 10px 10px 30px 0 rgba(204, 204, 204, 0.2);
    background-image: linear-gradient(179deg, #7de0db 10%, #68c7c1 110%, #5cb8b2 170%);
}

.modules-contact-us h3{
    width: 65.98958333333333vw;
    margin: 6.666666666666667vw 0;
    margin-left: 21.979166666666668vw;
    margin-right: 7.239583333333333vw;
    font-family: OpenSans;
    font-size: 1.875vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.72px;
    text-align: left;
    color: #fff;
}

.modules-contact-us button{
    margin-right: 21.979166666666668vw;
    text-align: center;
}

.modules-contact-us button .arr-demo{
    left: 2.5625vw;
}

