@media (max-width:599px) {

    /*#region landing page*/
    .landingPageHeading {
        font-size: 30px !important;
        width: 100% !important;
    }

    .landingPageBody {
        font-size: 16px !important;
        width: 100% !important;
        margin: 20px 0 !important;
    }

    .landingPageTextContainer {
        padding: 0 8.86%;
    }

    .landingPageImageLargeContainer {
        display: none !important;
    }

    .landingPageImageSmallContainer {
        display: block !important;
        min-height: 100% !important;
    }

    .mainLandingImage {
        transform: unset !important;
        margin: 0 auto !important;
        width: 140% !important;
    }

    .landingSectionContainer {
        min-height: 100vh !important;
    }

    .subcribe input {
        width: 182px !important;
        padding: 0 !important;
        height: 40px !important;
        min-height: 40px !important;
        padding-left: 6px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        background-color: rgba(204, 204, 204, 0.15) !important;
        border-radius: 5px 0px 0px 5px !important;
    }

    .btn-get-started {
        width: 130px !important;
        height: 40px !important;
        min-height: 40px !important;
        padding: 0px !important;
        border-radius: 7px !important;
        background-image: linear-gradient(to right, #7de0db -7%, #5cb8b2 118%) !important;
        font-family: OpenSans !important;
        font-size: 16px !important;
        font-weight: bold !important;
        color: #fff !important;
        border: none !important;
        cursor: pointer !important;
    }

    .name-field {
        width: 100% !important;
        margin-top: 15px !important;
        margin-right: 0 !important;
    }

    .name-field input {
        width: 100% !important;
    }

    .email-field {
        width: 100% !important;
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        margin-right: 0 !important;
    }

    .email-field input {
        width: 100% !important;
    }

    .trial-desc {
        font-size: 14px !important;
        text-align: center !important;
        margin-top: 15px !important;
    }

    .responsive-trial {
        width: 100% !important;
        margin: 0 auto !important;
    }

    /*#endregion landing page*/

    /*#region navigation*/
    .navBarContainer {
        margin: 17px 33px !important;
    }

    .navBarLogo {
        max-width: 150px !important;
    }

    .menuButton {
        display: block !important;
    }

    .languageButton {
        width: 65px !important;
        margin-left: auto !important;
    }

    .navbarLinksContainer {
        display: none !important;
    }

    .admin-user {
        display: none !important;
    }

    .buttonContainer {
        display: none !important;
    }

    /*#endregion navigation*/

    /*#region feeature*/
    .featuresContainer {
        padding: 66px 33px 66px !important;
    }

    .Feature-Header {
        font-size: 14px !important;
        padding-bottom: 5px !important;
    }

    .Feature-sub-header {
        font-size: 24px !important;
        margin-bottom: 45px !important;
    }

    .featureItemContainer {
        width: 300px !important;
        min-height: 300px !important;
    }

    .featuresIcon {
        width: 35% !important;
    }

    .featuresIcon2 {
        width: 35% !important;
    }

    .featuresIcon3 {
        width: 35% !important;
    }

    .featureHeader {
        font-size: 14px !important;
        margin: 14px 0 !important;
    }

    .featureText {
        font-size: 12px !important;
    }

    .featureText p {
        width: 80% !important;
    }

    .iconAndTitleContainer {
        padding-top: 30px !important;
    }

    /*#endregion feeature*/

    /*#region ourclients*/
    .OurClientsContainer {
        padding: 66px 33px 66px !important;
    }

    .OurClients-Header {
        font-size: 14px !important;
        padding-bottom: 5px !important;
    }

    .OurClientsIcon {
        width: 35% !important;
    }

    .OurClientItem {
        margin-right: 0 !important;
    }

    .hmLogoIcon {
        width: 25% !important;
    }

    .amazonLogo {
        position: relative !important;
        top: 0 !important;
    }

    .hmLogoIcon {
        right: 0 !important;
    }
    
    .CentralPatanaLogo {
        left: 0 !important;
    }
        
    .RELogo{
        right: 0 !important;
    }
    
    .BMWLogo{
        right: 0 !important;
    }
    
    .fujiLogo{
        right: 0 !important;
    }

    /*#endregion ourclients*/

    /*#region company review*/
    .review-right {
        width: 100% !important;
        height: 100% !important;
        flex-direction: column !important;
        align-items: center !important;
        padding: 20px 0 !important;
    }

    .companyReviewsHeader {
        font-size: 4.875vw !important;
        margin-bottom: 30px !important;
    }

    .companyReviewLogo {
        max-height: 100% !important;
        width: 40% !important;
        margin-right: 0 !important;
        margin-bottom: 30px !important;
    }

    .companyReviewContainer {
        justify-content: center !important;
    }

    .companyReviewsContainer {
        width: 70.552083vw !important;
        top: 44.552083vw !important;
    }

    .masLogo {
        width: 40% !important;
        margin-right: 0 !important;
        margin-bottom: 30px !important;
    }

    .companyReviewLogo2 {
        max-height: 100% !important;
        width: 40% !important;
        margin-right: 0 !important;
        margin-bottom: 30px !important;
    }

    /*#endregion company review*/

    /*#region CallToAction*/
    .callToActionSection {
        height: 112.291667vw !important;
    }

    .heading {
        font-size: 5.875vw !important;
    }

    .callToActionContainer {
        padding: 0 !important;
        padding-top: 185px !important;
    }

    .btn-schedule-demo-action {
        width: 100% !important;
        height: 45px !important;
        font-size: 22px !important;
        margin-bottom: 50px !important;
    }

    .btn-download-app {
        width: 100% !important;
        height: 45px !important;
        font-size: 22px !important;
        margin-bottom: 50px !important;
    }

    .arr-demo {
        width: 10% !important;
        top: 0.260417vw !important;
        left: 2.5625vw !important;
    }

    /*#endregion CallToAction*/

    /*#region scheduleDemo*/
    .schedule-container {
        background-color: #fcfdfd;
        padding-top: 124px !important;

    }

    .register-company-demo {
        font-size: 21px !important;
        padding-top: 26px !important;
        margin-bottom: 23px !important;
    }

    .form-demo {
        width: 85% !important;
        margin: 0 auto;
        margin-bottom: 30px !important;
    }

    .demo-text {
        display: flex;
        justify-content: center;
        padding-bottom: 50px;
        padding-left: 0 !important;
    }

    .demo-image {
        width: 60% !important;
        height: 324px !important;
    }

    .form-content .MuiFormControl-root label {
        font-size: 14px !important;
    }

    .column-2 .MuiFormControl-root {
        width: 45% !important;
    }

    .form-content .MuiFormControl-root {
        margin-bottom: 20px !important;
    }

    .btn-try-demo {
        width: 100% !important;
        height: 30px !important;
        margin: 23px 2px 26px 2px !important;
        border-radius: 10px;
        /* background-image: linear-gradient(to left, #7de0db 114%, #5cb8b2 17%); */
        background: linear-gradient(90deg, rgba(92, 184, 178, 1) 0%, rgba(125, 224, 219, 1) 100%);
        font-family: OpenSans;
        font-size: 14px !important;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: 0.44px;
        text-align: center;
        color: #fff;
        border: none;
        cursor: pointer;
    }

    /*#endregion scheduleDemo*/


    /* #region  modules */
    .modules-header {
        height: 300px !important;
    }

    .modules-title {
        font-size: 60px !important;
    }

    .modules-content-title {
        font-size: 33px !important;
    }

    .modules-content-subtitle {
        margin: 10px 0 66px !important;
        font-size: 20px !important;
    }

    .modules-box-container {
        height: 110px !important;
        overflow-x: overlay;
        overflow-y: hidden;
    }

    .modules-box-container .demo-module-box {
        min-height: 96px !important;
    }

    .modules-box-container .demo-module-box p {
        display: none !important;
    }

    .activated {
        box-shadow: inset 0 110px 0 0px #7addd7 !important;
        height: 110px !important;
    }

    .modules-overview-title {
        font-size: 22px !important;
        height: 47px !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .modules-description {
        margin-bottom: 10px !important;
    }

    .modules-description-number {
        margin: 30px 0 !important;
        padding: 8.6px 13px !important;
        height: 16px !important;
        width: 6px !important;
        margin-right: 33px !important;
        font-size: 14px !important;
    }

    .modules-description-text {
        font-size: 14px !important;
    }

    .modules-thumbnails {
        padding-top: 30px;
        align-items: center !important;
    }

    .modules-thumbnail {
        width: 75vw !important;
    }

    .modules-thumbnail-desc h5 {
        height: auto !important;
        padding: 3px 10px !important;
        font-size: 12px !important;
    }

    .modules-thumbnail-img {
        height: 85% !important;
    }

    .modules-thumbnail-imgs {
        width: inherit !important;
    }

    .modules-thumbnail-imgs img {
        width: 14.291667vw !important;
        height: 14.291667vw !important;
    }

    .modules-thumbnail-imgs .activeThumbnail {
        width: 14.291667vw !important;
        height: 14.291667vw !important;
    }

    .modules-contact-us {
        flex-direction: column !important;
        align-items: center !important;
    }

    .modules-contact-us h3 {
        font-size: inherit !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
        text-align: center !important;
    }

    .modules-contact-us .btn-schedule-demo-action {
        width: fit-content !important;
        margin-right: 0 !important;
        margin: 0 auto !important;
        margin-bottom: 50px !important;
    }

    .demo-contact-us {
        flex-direction: column !important;
        align-items: center !important;
        padding: 10px !important;
    }

    .demo-contact-us h3 {
        font-size: inherit !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
        text-align: center !important;
    }

    .demo-contact-us .btn-download-app {
        width: fit-content !important;
        margin-right: 0 !important;
        margin: 0 auto !important;
        margin-bottom: 50px !important;
        padding: 40px;
    }

    /* #endregion */

    /* #region  Privacy */
    .privacy-content {
        width: 80% !important;
        height: 50% !important;
        padding: 20px 26px !important;
    }

    .privacy-header {
        font-size: 21px !important;
    }

    .privacy-content-scroll {
        font-size: 12px !important;
    }

    .privacy-content ol {
        padding-left: 3% !important;
    }

    .privacy-logo {
        width: 50% !important;
    }

    .back-button {
        font-size: 100% !important;
    }

    .back-icon {
        width: 7% !important;
    }

    /* #endregion */

    /* #region blog */
    .blog-text {
        height: 280px !important;
    }

    .blog-text h1 {
        font-size: 45px !important;
    }

    .detailed-search-case .MuiInputBase-root input {
        font-size: 14px !important;
    }

    .detailed-search-icon,
    .search-icon-blog {
        width: 20px !important;
        right: 10.385417vw !important;
    }

    .search-icon-blog {
        bottom: 28px !important;
    }

    .search-blog .MuiInputBase-root input {
        font-size: 16px !important;
    }

    .search-blog {
        bottom: 15px !important;
    }

    .search-icon {
        width: 23px !important;
        bottom: 30px !important;
        right: 43px !important;
    }

    .blog-media {
        /* width: 20.833333333333332vw; */
        height: 250px !important;
        margin-bottom: 1.0416666666666667vw;
        object-fit: contain;
        transition: 0.5s ease-in-out;
        background-size: cover !important;
    }



    .no-blogs {
        padding-top: 30px !important
    }

    .blog-card-contents {
        padding: 20px !important;
    }

    .blog-card-content {
        margin-right: 0 !important;
        width: 475px !important;
    }

    .blog-addmore-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 80px;
    }

    .blog-pagination {
        position: relative;
        bottom: 80px;
    }

    .blog-card {
        padding: 6px !important;
    }

    .blog-card-title {
        font-size: 24px !important;
    }

    .blog-card-date {
        font-size: 9px !important;
    }

    .blog-card-desc {
        font-size: 12 !important;
        margin-top: 12 !important;
        margin-bottom: 20.000000000000004px !important;
        height: 100% !important;
    }

    .blog-card-actions {
        padding: 0 10px !important;
    }

    .blog-previous-btn,
    .blog-next-btn,
    .blog-number-btn {
        font-size: 16px !important;
    }

    .blog-number-btn div {
        margin-right: 10px !important;
    }

    .p-custom {
        padding: 15px 10px 10px 10px !important;
    }

    .blog-copyright {
        width: 100%;
        margin-top: 60px !important;
    }

    .blog-copyright .copyright {
        margin-left: 20.000000000000004px !important;
    }

    .blog-footer {
        padding-top: 60px !important;
    }

    .bg-none {
        background-color: transparent !important;
    }

    .box-shadow-none {
        box-shadow: none !important;
    }

    .w-300 {
        width: 300px !important;
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
    }

    .search-blog .MuiInput-underline::before {
        border-bottom: 0 !important;
    }

    .blog-first-contents {
        display: none !important;
        padding: 0 8.854166666666666vw;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column !important;
    }

    .blog-first-content {
        width: 100% !important;
        flex-direction: column-reverse !important;
    }

    .blog-first-text {
        width: auto !important;
        min-height: 12.34375vw;
        margin: 20px 10px !important;
        background-color: #fff;
        position: relative !important;
        padding: 1.9270833333333333vw 2.65625vw 1.875vw 2.7083333333333335vw;
    }

    .blog-first-text h4 {
        font-size: 24px !important;
    }

    .blog-first-text span {
        font-family: OpenSans;
        font-size: 16px !important;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: left;
        color: white;
    }

    .blog-first-text-span {
        color: #999 !important;
    }

    .blog-first-view-details {
        font-family: OpenSans;
        font-size: 16px !important;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: left;
        color: #5cb8b2;
    }

    .blog-first-text p {
        font-family: OpenSans;
        font-size: 16px !important;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-top: 0.8333333333333334vw;
        margin-bottom: 1.0416666666666667vw;
        height: 5.208333333333333vw;
        overflow: hidden;
        margin-bottom: 30px !important;
    }

    .blog-first-sidebars {
        display: none !important;
    }

    .blog-first-image {
        position: relative;
        margin-left: 11.979166666666666vw;
        z-index: 1;
    }

    .blog-first-image {
        width: 100% !important;
        height: auto !important;
        margin: 0 !important;
        object-fit: contain;
    }

    .blog-first-sidebars {
        min-height: 32.552083333333336vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .blog-first-sidebar {
        width: 19.53125vw;
        padding: 1.3541666666666667vw 2.6041666666666665vw 1.3541666666666667vw 2.6041666666666665vw;
        border-radius: 10px;
        box-shadow: 25px 25px 75px 0 rgba(204, 204, 204, 0.15);
        background-color: #fff;
        margin-bottom: 2.6041666666666665vw;
    }

    .blog-first-sidebar .MuiList-root .MuiListItem-root {
        padding-top: 0px;
        padding-bottom: 0.3645833333333333vw;
    }

    .blog-first-sidebar .MuiList-root .MuiListItem-button:hover {
        background-color: transparent !important;
    }

    .blog-first-sidebar .MuiList-root .MuiListSubheader-root {
        margin-bottom: 1.5104166666666667vw;
        font-family: OpenSans;
        font-size: 1.6666666666666667vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.34;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }


    .blog-first-sidebar .MuiList-root .MuiListItemText-root .MuiTypography-root {
        font-family: OpenSans !important;
        font-size: 1.1458333333333333vw !important;
        font-weight: bold !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45 !important;
        letter-spacing: normal !important;
        text-align: left;
        color: #777;
    }

    .blog-dropdown-icon {
        margin-left: 0.78125vw;
        width: 1.25vw;
        object-fit: contain;
        transition: 0.5s ease-in-out;
    }

    .rotatex-180 {
        transition: 0.5s ease-in-out;
        transform: rotateX(180deg);
    }

    .rotatez-180 {
        transition: 0.5s ease-in-out;
        transform: rotateZ(180deg);
    }

    .blog-contactus {
        padding: 2.7083333333333335vw 2.4479166666666665vw 2.65625vw 2.5vw;
        border-radius: 10px;
        box-shadow: 10px 10px 30px 0 rgba(204, 204, 204, 0.2);
        background-image: linear-gradient(to right, #7de0db -14%, #5cb8b2 126%);
    }

    .blog-btn-contactus img {
        width: 1.3020833333333333vw;
        object-fit: contain;
        position: relative;
        left: 2.8645833333333335vw;
    }

    .blog-btn-contactus {
        cursor: pointer;

        width: 19.791666666666668vw;
        height: 7.65625vw;
        border-radius: 10px;
        border: solid 2px #fff;
    }

    .blog-btn-contactus span {
        font-family: OpenSans;
        font-size: 1.5625vw;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }

    .blog-previous-btn,
    .blog-next-btn,
    .blog-number-btn {
        font-family: OpenSans;
        font-size: 1.6666666666666667vw;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #2c333e;
        cursor: pointer;
        border: none !important;
    }

    .blog-card-view-details {
        font-size: 14px !important;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: left;
        color: #5cb8b2;
    }

    .blog-number-btn div {
        margin-right: 1.0416666666666667vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blog-number-active {
        width: 20px !important;
        height: 20px !important;
        border-radius: 4px;
        background-color: #7de0db;
        color: #fff;
    }

    .detailed-case-content h2 {
        font-size: 24px !important;
        margin-bottom: 0 !important;
        line-height: 0.8 !important;
    }

    .detailed-case-card-date {
        font-size: 12px !important;

    }

    .detailed-case-detailed-content {
        margin-top: 20px !important;
    }

    /* #endregion */

    /* #region case studies */

    .case-text {
        height: 450px !important;
        padding: 100px 33px !important;
        padding-bottom: 10px !important;
        padding-top: 100px !important;
    }

    .case-text h1 {
        font-size: 32px !important;
        margin-bottom: 20px !important;
    }

    .case-text p {
        font-size: 12px !important;
    }

    .case-first-image {
        display: none;
    }

    .btn-case-hyperlink {
        width: 120px !important;
        height: 40px !important;
        font-size: 14px !important;
    }

    .btn-case-below {
        width: 100px !important;
        height: 10px !important;
        font-size: 9px !important;
    }

    .search-case {
        width: 170px !important;
        height: 40px !important;
    }

    .search-case .MuiInputBase-root input {
        font-size: 12px !important;
    }

    .search-case-icon {
        width: 18px !important;
        bottom: -4px !important;
        right: 33px !important;
    }

    .detailed-case {
        padding: 0 !important;
        padding-top: 40px !important;
    }

    .detailed-case-active-breadcrum,
    .detailed-case-breadcrum-links {
        font-size: 8px !important;
    }

    .detailed-case-breadcrum-container img {
        width: 10px !important;
    }

    .detailed-case-detailed-content {
        padding-right: 20px !important;
        padding-bottom: 40px !important;
    }


    /* #endregion */


    /* #region download app */
    .appdownload-content{
        height: 100vh !important;
    }

    .appdownloadLeftContent {
        padding-right: 0 !important;
        padding-top: 82px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .appdownloadRightContent {
        height: auto !important;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center !important;
    }

    .appdownloadTitle{
        font-size: 24px !important; 
        width: 100% !important;
        text-align: center !important;
    }

    .appdownloadubTitle{
        font-size: 14px !important;
        width: 100% !important;
        max-width: 90% !important;
        text-align: center !important;
    }
    
    .appdownload-main-image{
        margin-left: 20px !important;
        width: 35% !important;
    }

    .appdownload-downloadbtn{
        display: flex;
        flex-direction: column !important;
    }

    .appdownload-downloadbtn button{
        font-size: 14px !important;
        margin-bottom: 10px;
        padding: 10px 10px;
        width: 150px !important;
    }

    .appdownload-downloadbtn button svg{
        font-size: 14px !important;
        margin-right: 5px !important;
    }

    /* #endregion */

    .mb-80 {
        margin-bottom: 40px !important;
    }

    .py-case-170 {
        padding-left: 33px !important;
        padding-right: 10px !important;
    }

    .MuiGrid-spacing-xs-2 {
        width: 100% !important;
    }

    .mt-30 {
        margin-top: 20px !important;
    }

    
    .responsive-container{
        display: flex;
        justify-content: center;
    }

}

@media (max-width:902px) {

    /*#region navigation*/
    .navBarContainer {
        margin: 17px 33px !important;
    }

    .navBarLogo {
        max-width: 100px !important;
    }

    .menuButton {
        display: block !important;
    }

    .languageButton {
        margin-left: auto !important;
    }

    .navbarLinksContainer,
    .app-download-links {
        display: none !important;
    }

    .admin-user {
        display: none !important;
    }

    .buttonContainer {
        display: none !important;
    }

    .MuiGrid-spacing-xs-2 {
        width: 100% !important;
    }

    .float-right{
        float: center !important;
      }
      
      .float-left{
        float: center !important;
      }
      .w-70{
        width: 100% !important;
      }

      .w-400{
        width: 100% !important;
      }
    /*#endregion navigation*/
}

@media (max-width:1080px) {

    /* #region case studies */
    .case-first-image {
        max-height: 39.0625vw !important;
    }

    /* #endregion */
}

@media (min-width:600px) {
    .mr-51 {
        margin-right: 51px !important;
    }

    .p-0 {
        padding: 0 !important;
    }

    .blog-card-actions {
        padding: 0 !important;
    }

   
}

@media (max-width:600px){
    .securityPadding{
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        margin-top: 10px !important;
    }

    .security-image-right{
        float: none !important;
        padding-right: 0 !important;
    }
    
    .security-image-left{
        float: none !important;
        padding-left: 0 !important;
    }

    .security-image{
        width: 90% !important;
    }
}

@media (max-width:768px) {
    .blog-card-desc {
        height: 100% !important;
    }
}