.article{
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    width: 65%;
    margin: 16px;
    display: flex;
    flex-direction: row;
    padding: 30px;
    transition: 0.5s ease-in-out;
} 

.article-container{
    width: 100%;
    display: flex;
    
}

.article-content{
    width: 100%;
    /* margin: 16px; */
    /* padding: 30px; */
}

.help-side-article{
    width: 24%;
}

.help-side-article h2{
    padding-left: 16px;
}

.article-detailed-content img{
    max-width: 800px;
    object-fit: contain;
}

.article-detailed-content{
    overflow-wrap: break-word;
}

@media (max-width:887px){
    .article-container{
        align-items: center;
     }
     .article{
         width: 85%;
     }
}