.form-contactus .form-content .MuiFormControl-root .MuiInput-formControl{
    margin-top: 1.40625vw !important;
}

.contact-success-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 11.458333333333334vw 0;
}

.contact-success{
    font-family: OpenSans;
    font-size: 1.9791666666666667vw;
    margin: 1.0416666666666667vw 0 0.9375vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: 0.76px;
    text-align: left;
    color: #fff;
}

.box-white-bg{
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    background-color: #fff;
    border-radius: 5px;
}

.checkmark-icon{
    width: 150px;
}

.box-white-bg span{
    font-family: OpenSans;
    font-size: 1.1458333333333333vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.91;
    letter-spacing: 0.44px;
    text-align: center;
    color: #5cb8b2;
    margin: 0 0.7291666666666666vw;
}