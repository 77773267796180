.login-container{
    background: url('../../assets/img/privacy-bg.png');
    height: 100vh;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-content{
    width: 100%;
}

.register-company{
    font-size: 18px !important;
    font-weight: bold;
    padding: 10px 0;
}

.register-company TextField{
    font-family: nunito;
}

.form-login{
    max-width: 350px;
    padding: 30px 45px;
    margin: 0 auto;
    background: white;
    border-radius:5px; 
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.bgwhite{
    background-color: white;
    width: 100%;
    height: 64px;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.btn-try{
    margin-top: 15px !important;
    margin-left: 7px !important;
    width:100%;
    background:rgb(72,131,255) !important; 
    color:white !important;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.btn-try:hover{
    background-color: rgb(50, 91, 178) !important;
}

.error-message{
    color: #f44336;
}

@media(max-width:549px){
    .form-demo{
        width: 300px;
    }
}

@media (max-width:959px){
    .schedule-container{
        height: 100%;
        overflow: auto;
    }
}