body{
  font-family: 'OpenSans' !important;
}

.bgone{
  /* background: url(./assets/img/onebig.png); */
  /* background-size: cover ; */
  overflow: hidden;
}

.bgsecond{
  /* background: url(./assets/img/bigsecond.png); */
  /* background-size: cover ; */
  background-color: rgba(204, 204, 204, 0.05);
  overflow: hidden;
}

.stepperContainer .MuiPaper-root{
  background-color: transparent !important;
}

.bgthird{
  /* background-image: linear-gradient(to bottom, #7de0db 9%, #68c7c1 113%, #5cb8b2 176%); */
  /* background: url(./assets/img/footer.png); */
  background-color: rgba(204, 204, 204, 0.05);
    /* background-size: cover ; */
}

.map-container{
 border-bottom: 1px solid lightgray;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiPaper-root{
  background: transparent;
}

/* .MuiPaper-elevation8{
  box-shadow: none !important;
} */

.flex-centered{
  display: flex;
    align-items: center;
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.float-right{
  float: right;
}

.float-left{
  float: left;
}
.w-70{
  width: 70%;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loadingGif{
  width: 100%;
  height: 100vh;
  object-fit: contain;
}

.demo-contact-us{
  display: flex;
  width: 100%;
  /* padding: 128px 422px 127px; */
  justify-content: center;
  align-items: center;
  box-shadow: 10px 10px 30px 0 rgba(204, 204, 204, 0.2);
  background-image: linear-gradient(179deg, #7de0db 10%, #68c7c1 110%, #5cb8b2 170%);
}

.demo-contact-us h3{
  width: 65.98958333333333vw;
  margin: 6.666666666666667vw 0;
  margin-left: 21.979166666666668vw;
  margin-right: 7.239583333333333vw;
  font-family: OpenSans;
  font-size: 1.5625vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.72px;
  text-align: left;
  color: #fff;
}

.demo-contact-us button{
  margin-right: 18.979167vw;
  text-align: center;
}

.demo-contact-us button .arr-demo{
  left: 1.5625vw;
}

.btn-download-app{
  margin-top: 16.09375vw;
  cursor: pointer;
  width: 23.791667vw;
  height:  4.6875vw;
  border-radius: 10px;
  border: solid  0.20833333333333334vw #fff;
  font-family: OpenSans;
  font-size:  1.5625vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 0 2.2916666666666665vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pr-0{
  padding-right: 0px !important;
}

.pr-10{
  padding-right: 10px !important;
}
.pl-10{
  padding-left: 10px !important;
}

.pl-0{
  padding-left: 0px;
}

.pt-0{
  padding-top: 0 !important;
}

.pb-0{
  padding-bottom: 0 !important;
}

.pl-20{
  padding-left: 20px;
}

.pr-20{
  padding-right: 20px;
}

.w-400{
  width: 400px;
}

.font-16{
  font-size: 16px !important;
}

.font-22{
  font-size: 22px !important;
}
.font-20{
  font-size: 20px !important;
}

.mb-1{
  margin-bottom: 4px;
}

.flex-column{
  flex-direction: column;
}

.text-white{
  color: white;
}


.oct-search-capsule .MuiOutlinedInput-root{
  border-radius: 30px;
}

.oct-search-capsule{
  min-width: 120px !important;
}