.privacy{
    background: url('../../assets/img/privacy-bg.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    height: 100vh;
}

.privacy-content{
    width: 35.9375vw;
    height: 31.25vw;
    padding:  1.5625vw 2.0833333333333335vw;
    border-radius: 10px;
    box-shadow: 20px 20px 60px 0 rgba(204, 204, 204, 0.2);
    background-color: #fff;
}

.bgwhite{
    background-color: white;
    width: 100%;
    height: 3.3333333333333335vw;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.text-header{
    font-size: 6em;
    font-weight: lighter;
}

.text-content-header{
    font-size: 3em;
    font-weight: lighter;
}

.privacyContentContainer{
    display: flex;
    flex-direction: row;
    padding: 0 7%;
}

.privacySide{
    width: 30%;
}

.privacyContent{
    width: 70%;
}

.privacy-content ol li{
    margin-bottom: 1.5625vw;
}

.privacy-content ol{
    padding-left:  30px;
}

.privacy-logo{
    margin: auto 0;
    width: 15.104166666666666vw;
}

.back-icon{
    width: 1.25vw;
    margin-right: 0.5208333333333334vw;
}

.back-button{
    margin: 0 0 0 0.9635416666666666vw;
    height: 2.3958333333333335vw;
    /* margin-bottom: 102px; */
    font-family: OpenSans;
    font-size:  1.5625vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: none;
    cursor: pointer;
}

.privacy-content-scroll::-webkit-scrollbar {
    width: 10px;
  }
  
  .privacy-content-scroll::-webkit-scrollbar-track {
    background-color: rgba(92, 184, 178, 0.1);
    border-radius: 10px;
  }
   
  .privacy-content-scroll::-webkit-scrollbar-thumb {
    height: 25vw;
    border-radius: 10px;
    background-color: #8dcdc9;

  }
  
  .privacy-content-scroll::-webkit-scrollbar-thumb:hover {
    background: #8dcdc9; 
  }

  .privacy-content-scroll{
    overflow: auto;
    padding-right: 1.5625vw;
    height: 100%;
    font-family: OpenSans;
    font-size: 0.9375vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }

  .privacy-header{
    font-family: OpenSans;
    font-size: 1.6666666666666667vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 0.64px;
    text-align: left;
    color: #55a6a0;
    margin: 0;
    margin-bottom: 1.875vw;
  }

@media (max-width:1280px) {
    
    
}